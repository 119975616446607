import logo from './bitRiot_logo.png';
import './App.css';

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a className="App-link" href={`mailto:${email}${params}`}>{children}</a>;
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          does your project need this domain?
        </p>
        <Mailto email="contact@bitRiot.com" subject="bitRiot Inquiry" body="">
          contact@bitRiot.com
        </Mailto>        
      </header>
    </div>
  );
}

export default App;
